.ui--navigator {
  @apply fixed inset-x-0 bottom-0 z-20 flex justify-center;

  &-container {
    @apply w-full max-w-2xl rounded-t-md bg-white/10 backdrop-blur-3xl;
    height: 72px;
  }

  &-menu {
    @apply grid justify-center gap-4 py-1 max-sm:!grid-cols-5 max-sm:gap-0;
    grid-template-columns: repeat(5, 80px);

    .li {
      @apply flex items-center justify-center;
    }

    .router-link {
      @apply px-2 py-3;

      &.center {
        @apply relative pt-[32px];

        .icon {
          @apply absolute inset-x-0 bottom-9 mx-auto object-contain object-center;
        }
      }

      .icon-svg {
        @apply mx-auto size-5 text-transparent;
      }

      .text {
        @apply text-nowrap text-sm text-theme-light max-sm:text-xs;

        &.sm {
          @apply max-sm:text-sm;
        }
      }
    }
  }
}
