html,
body {
  @apply scroll-smooth bg-theme-back font-primary text-white antialiased;
  background-image: linear-gradient(to top, rgba(#000, 50%) 0%, rgba(#000, 50%) 100%),
    url('/static/images/bg.png');
  background-repeat: no-repeat;
  background-size: auto 88vh;
  background-position: center top;
  // background-attachment: fixed;
}

a {
  @apply inline-block cursor-pointer select-none text-inherit no-underline outline-none;
}

img {
  @apply pointer-events-none select-none;
}

label {
  @apply select-none;
}

button,
input,
select,
textarea {
  @apply border-none bg-transparent font-primary shadow-none outline-none first-line:text-inherit;
}

button {
  @apply select-none;
}

input,
select,
textarea {
  @apply w-full;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    @apply m-0 appearance-none;
  }

  &[type='number'] {
    appearance: textfield;
  }
}
