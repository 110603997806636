@layer components {
  .btn {
    @apply relative flex items-center justify-center rounded-full;

    * {
      @apply pointer-events-none;
    }

    &-text {
      &:hover {
        .text {
          @apply underline;
        }
      }
    }

    &-primary {
      @apply bg-black/10 text-theme;
      background-image: linear-gradient(to bottom, #fff 0%, #fff 45%, #d3a13a 47%, #fffdf5 100%);

      &::before {
        @apply pointer-events-none absolute inset-[2px] rounded-full bg-cover bg-center bg-no-repeat;
        content: '';
        background-image: url('/static/images/soft-rock.png');
      }

      .icon,
      .text {
        @apply z-10 font-bold text-theme-dark;
        text-shadow: 0 1px #fff;
      }
    }

    &-secondary {
      color: rgba(#4c3016, 80%);
      background-image: linear-gradient(to bottom, #d3a13a 0%, #e6ddb9 100%);
    }

    &:disabled,
    &.disabled {
      @apply cursor-not-allowed opacity-50;
    }
  }
}
