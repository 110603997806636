@layer components {
  .ui--pagination {
    &-result,
    &-render {
      @apply my-4 flex w-full items-center justify-center gap-2;
    }

    &-render {
      .buttonPage {
        @apply h-8 w-8 cursor-pointer rounded-lg bg-[#0000004d] text-base font-bold text-white;

        &:disabled {
          @apply cursor-not-allowed;
        }
      }

      .next,
      .prev {
        @apply flex h-8 w-8 cursor-pointer items-center justify-center rounded-lg bg-gradient-to-b from-[#fffcc4] to-[#fda33e] text-lg font-bold text-white;

        &:disabled {
          @apply cursor-not-allowed bg-[#0000004d] bg-none;
        }
      }

      .active {
        @apply bg-gradient-to-b from-[#fffcc4] to-[#fda33e];
      }
    }

    &-result {
      @apply py-3;
    }
  }
}
