.ui--playable {
  &-betflix {
    .btn {
      &-tab {
        &.active {
          @apply text-theme;

          .icon {
            // @apply bg-theme-red;
          }
        }

        .icon {
          @apply flex size-12 items-center justify-center rounded-full;
        }

        .text {
          @apply text-sm;
        }
      }
    }
  }

  &-spinwheel {
    &-wrapper {
      @apply pointer-events-none relative mx-auto select-none;

      .sWheel {
        &-center {
          img {
            @apply mx-auto;
          }
        }
      }
    }

    .btn {
      &-spin {
        @apply h-12 w-full rounded-full bg-theme text-lg font-bold text-black;
      }
    }
  }
}
