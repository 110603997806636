.ui--guard {
  @apply flex items-center justify-center p-4;
  min-height: calc(100vh - 100px);

  &-context {
    @apply relative mx-auto w-full justify-center rounded-3xl bg-black/40 px-8 pb-8 pt-12 ring-1 ring-white/10 backdrop-blur-lg;
    max-width: 480px;
  }

  &-header {
    @apply mb-10 grid gap-6;

    .logo {
      @apply mx-auto object-contain object-center max-sm:w-full;
    }

    .h2 {
      @apply text-3xl font-bold text-theme;
    }
  }

  &-body {
  }

  &-form {
    @apply mx-auto w-full max-w-96;

    .bank {
      .text-2 {
        @apply text-black;
      }
    }

    .btn {
      @apply h-12;

      &-submit {
        @apply w-full;
      }
    }
  }

  &-step {
    @apply grid grid-cols-3 gap-4;
  }
}
