.ui--header {
  @apply border-b border-white/15 bg-black/30;

  &-container {
    @apply flex items-center justify-between gap-4 px-6 max-sm:px-2;
    height: 72px;

    .columns {
      &-end {
        @apply grid grid-flow-col justify-center gap-4 max-sm:gap-2;

        &.is-acc {
          @apply max-sm:hidden;
        }
      }
    }

    .logo {
      @apply object-contain object-center max-sm:w-28;
    }

    .router-link {
      @apply p-2 text-theme;
    }
  }
}
