.ui--account {
  @apply pt-8;

  &.info {
  }

  &.affiliate {
  }

  &.redeem {
  }

  &.pwd {
  }

  &-container {
    &.context {
      @apply mx-auto max-w-2xl rounded-2xl bg-black/40 p-6 ring-1 ring-white/10 backdrop-blur-lg max-sm:p-4;
    }

    .btn {
      &-grid {
        @apply grid grid-flow-row gap-2 px-4 text-center max-sm:px-2;
      }
    }
  }
}
