.ui--home {
  &-container {
  }

  &-top {
  }

  &-banner {
    .slide {
      @apply mx-auto h-96 w-full max-sm:h-auto;
    }

    .slick {
      &-arrow {
        display: none !important;
      }

      &-list {
        @apply relative m-0 overflow-hidden p-0;
        transform: translateZ(0);
      }

      &-track {
        @apply mx-auto flex;
      }

      &-slide {
        @apply h-full;
      }
    }
  }

  &-games {
    .context {
      @apply grid grid-flow-col justify-start gap-4 overflow-x-auto pb-2;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        @apply h-[6px];
      }

      &::-webkit-scrollbar-track {
        @apply rounded-full bg-black/10;
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded-full bg-black/30;
      }
    }
  }

  &-streaming {
    .controls {
      background-image: linear-gradient(to top, rgba(black, 0.8) 0%, transparent 25%);
      transition: all 200ms ease-in-out;
    }

    .live {
      @apply pointer-events-none absolute left-2 top-2 inline-block rounded bg-red-500 px-1 text-xs capitalize;
    }

    .list {
      @apply grid grid-flow-col justify-start gap-4 overflow-x-auto p-2;
      scroll-snap-type: x mandatory;

      &::-webkit-scrollbar {
        @apply h-[6px];
      }

      &::-webkit-scrollbar-track {
        @apply rounded-full bg-black/10;
      }

      &::-webkit-scrollbar-thumb {
        @apply rounded-full bg-black/30;
      }
    }
  }
}
