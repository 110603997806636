.ui--blog {
  &-container {
    @apply mt-4 grid grid-cols-3 gap-4 rounded-2xl bg-neutral-800/75 p-4 backdrop-blur-3xl max-sm:grid-cols-2;
  }

  &-content {
    @apply mt-4 rounded-2xl p-4;
  }

  &-item {
    @apply overflow-hidden rounded-lg bg-white/20 ring-1 ring-white/10;

    .title {
      @apply text-lg font-bold text-theme;
    }
  }
}
